// html:not([dir="rtl"]) .sidebar {
//     margin-left: -220px !important;
// }

// html:not([dir="rtl"]) .sidebar-lg-show .sidebar, html:not([dir="rtl"]) .sidebar-show .sidebar {
//     margin-left: 0 !important;
// }

// .sidebar-lg-show {
//     .sidebar {
//         width: 220px !important;
        
//         .nav {
//             width: 220px !important;
//         }
//     }
    

//     // .sidebar-minimized .sidebar .sidebar-nav {
//     //     padding-bottom: 50px;
//     //     .nav {
//     //         width: 50px !important;
//     //     }
//     // }
//     .sidebar-nav {
//         width: 220px !important;
//     }

//     .sidebar-minimized {
//         .sidebar {
//             .sidebar-nav {
//                 overflow: visible;
//                 width: 50px;
//                 .nav {
//                     width: 50px !important;
//                 }
//             }
//         }            
//     }
    
// }





// .sidebar-nav .nav {    
//     width: 50px !important;
// }



.pos-container {
    .pos-content {
        .form-group {
            font-size: 12px;
            .form-control {
                font-size: 12px;
            }
            .btn {
                padding: 0.3rem 0.6rem !important
            }
        }
        .table-pos {
            tbody tr td {
                vertical-align: middle;
                .form-control {
                    font-size: 12px;
                }
            }
        }
        .table-total {
            margin-top: 25px;            
            tbody tr td {
                border-bottom: none !important;
                border-top: none !important;
                padding: 2px 15px !important;
                font-size: 12px;
            }
            tbody tr td.td-total{
                font-size: 32px;
                font-weight: bold;
                color : #2d4eaa
            }
        }
    }
}

.container-laporan {
    padding: 60px;
}

.laporan {
    .header {
        .title {
            font-weight: bold !important;
            font-size: 10pt;
        }
        .tanggal-cetak {
            font-size: 10px;
        }
        .info {
            font-size: 85%;
        }        
    }
    .table-laporan {
        margin-top: 20px;
    }
    .table-laporan thead tr th,
    .table-laporan tbody tr td,
    .table-laporan tfoot tr td {
        font-size: 85% !important;
        border: 1px solid #000 !important;
    }

    .table-laporan {
        tbody {
            tr.bold {
                td {
                    font-weight: bold;
                }
            }
            tr {
                td {
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                }
            }
        }
    }
}

.laporan-potrait {
    position: relative;
    background: #fff;
    border: 1px solid #f4f4f4;
    padding: 20px;
    margin: 10px 25px;
    width: 20.2cm;
    font-family: Sans-Serif, Helvetica, Arial;
    font-size: 10pt;
    margin-top: 1cm;
}
.laporan-landscape {
    position: relative;
    background: #fff;
    border: 1px solid #f4f4f4;
    padding: 20px;
    margin: 10px 25px;
    width: 28.2cm;
    font-family: Sans-Serif, Helvetica, Arial;
    font-size: 10pt;
    margin-top: 1cm;
}

.laporan-potrait.bukti-pengeluaran {
    .nomor-bukti-pengeluaran, .ttd-pengeluaran {
        font-size: 12px;
    }    
    .ttd-pengeluaran {
        margin-top: 40px;
    }
    .nama-ttd {
        margin-top: 70px;
    }
    .table {
        tbody tr td {
            border-top: none;
            font-size: 12px;
            padding: 4px 8px;
            border-bottom: 1px solid #ddd;
        }
    }
}