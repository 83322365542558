

.strukprint-container {
    // margin: 0.1cm;
    // width : 80cm;
    // width : 100%;
    @media print {
        @page {
           margin: 0.10cm;
           width : 70cm;
       }
    }

    .company-name {
        font-family: "Courier New";
        font-weight: bold;
        font-size: 20px;        
    }

    .company-address, .company-address, .company-city, .title, .desc, .kasir-name, .tanggal-penjualan, .memo {
        font-size: 9pt;
        font-family: "Courier New";
    }

    .title {
        // font-weight: bold;
        // font-size: 13px;
        margin-top: 10px;    
    }

    .table tbody tr td,
    .table tfoot tr td {
        font-family: 'Tahoma';
        font-size: 11px !important;
        color: #333;
        padding: 3px !important;
        // padding-bottom: 3px !important;
        // padding-left: 4.5px !important;
        // padding-right: 4.5px !important;
    }

    .table tbody tr:last-child td {
        border-bottom: 1px solid #000;
    }

    
    background-color: white;
    width: 70mm;
    // max-width: 70mm;
    font-size: 14px;
    font-family: arial, sans-serif;
    padding-left: 15px;
    padding-right: 15px;

    .table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        border : none;
        font-size: 11px;
    }
    
    // // .company-address, 
    // // .company-city, 
    // // .company-phone{
    // //     font-size: 13px;
    // // }

    

    // .desc {
    //     padding-bottom: 10px;    
    // }

    // .box-tanggal {
    //     margin-bottom: 0 !important;
    // }

    .table tr td {
        // padding: 5px 0 5px 0 !important;
        border-top : none;
        color : #000;        
    }

    // .table tr.tr-total  td {
    //     padding: 0 0 4px 0 !important;
    //     font-weight: normal !important;
    // }

    // .table .tr-border td {
    //     border-top : 1px solid #c8ced3 !important;
    // }

   
      
      
    // @media print{
    //     .hide-on-print{
    //         display: none;
    //         width: 100%;
    //     }
    // }
    .memo {
        font-size: 14px;
        font-weight: bold;
        .memo-description {
            font-size: 12px;
            font-weight: normal;
        }
    }
}

.print-struk {
    padding: 0 !important;
    margin-left: -10px !important;
    padding-left: -100px !important;

    @media print {
        body,
        html,
        #wrapper {
            width: 100%;
            margin: 0px;
            padding: 20px;
            border: 1px solid red;
        }
    }   
}

.body-print {
    width: 100%;
    margin: -20px;
}