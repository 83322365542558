// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

.alert-popup {
    position: fixed;
    top: 20px;
    right: -300px;
    // right: 0px;
    /* Mulai di luar layar */
    background-color: #ffffff;
    color: #484848;
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    // animation: slideIn 0.5s forwards, fadeOut 0.5s ease-in-out 3s forwards;
    z-index: 3000;    
    border:1px solid #cecece;
    
}

.alert-popup.show {
    animation: slideIn 0.5s forwards;
}
.alert-popup.hide {
    animation: fadeOut 0.5s ease-in-out 3s forwards;
}

.alert-popup.success {
    border-left: 5px solid #008909;
}


@keyframes slideIn {
    from {
        right: -300px;
    }

    to {
        right: 20px;
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}