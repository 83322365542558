body {
    margin: 0 auto;    
    margin-top: 0px;
    background-color: #cecece !important;
    height: 29cm;
}

@page {
    size: auto !important;
}

@media print{
    .containeri {    
        margin: 0 !important;
    }
}
.containeri {    
    width: 21cm !important;    
    display: flex;
    flex-direction: row;    
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 auto;
    height: 29.3cm;
}
.container-left {
    width: 9cm; ;    
    background-color: #FDCA37;
    /* display: flex;        
    flex-direction: column;        
    align-items: center;
    justify-content: flex-start; */    
    /* height: 28.4cm;     */
}

.primary-title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: bold;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial;
    padding-left: 10px;
    padding-right: 10px;
}

.container-left .img-container {
    margin-top: 40px;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    position: relative;
    background-color: #fff;    
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: -webkit-box; /* wkhtmltopdf uses this one */
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center; /* wkhtmltopdf uses this one */
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    margin: auto;
}

.container-left .img-container .striped {    
    border-radius: 70px;
    width: 140px;
    height: 140px;
    background-color: #FDCA37;
    display: -webkit-box; /* wkhtmltopdf uses this one */
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center; /* wkhtmltopdf uses this one */
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
}
.container-left .img-container img {
    object-fit: cover;
    border-radius: 65px;
    width: 130px;
    height: 130px;
    margin: auto;
    /* display: block; */
    align-self: center;
    justify-self: center;
    /* display: none; */
}

.container-left .employee-name {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    font-size: 32px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial;
    font-weight: bold;
    text-align: center;
    line-height: 2.2rem;
}

.contact-container {
    margin-top: 80px;    
    width: 100%;    
    align-items: center;    
}

.contact-container .title {
    width: 100%;
    text-align: center;
}

ul.ul-contact {
    list-style: none;
    width: 100%;
    padding-left: 0 !important;
    margin-top: 40px;
}

.ul-contact li {    
    /* display: flex; */
    display: -webkit-box;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    margin-bottom: 5px;        
}

.ul-contact li .icon-container {
    background-color: #000;
    width: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;    
    flex-direction: column;    
    justify-content: center;
    align-items: center;
}

.ul-contact li .icon-content {
    margin: auto;
    margin-left: 10px;
    vertical-align: middle;
    margin-right: 40px;
}

img.icon {
    width: 15px;
    height: 15px;
}

/* .icon-container img.icon {
    margin-left: 20px;
} */


.title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;    
}



.container-right {
    width: 100%;
    background-color: #fff;        
    padding-left: 20px;    
}


.title-container {
    /* display: flex; */
    display: -webkit-box;
    flex-direction: row;
    -webkit-box-pack: justify;
    margin-top: 20px;
}

.title-container .icon-container{
    flex: 1;
}

.title-container .icon,
.title-container .icon {
    width: 40px;
    height: 40px;
}

.label-title {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 0px;
    background-color: #0e0606;    
    color : #fff;
    display: flex;    
    align-items: center;
    padding-left: 20px;
    font-size: 15px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial;
    width: 88%;
}

.ul-experience {
    margin-top: 30px;
    list-style: none;
}
.ul-experience li {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial;
    margin-top: 5px;
    position: relative;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
}

.ul-experience li .experience-perusahaan {
    font-weight: normal;
    font-size: 14px;
    width: 40%;
}
.ul-experience li .experience-posisi {
    font-size: 12px;
    margin-top: 3px;
    color : #5c5858
}
.ul-experience li .experience-tahun {
    font-size: 14px;
    margin-left: 10px;    
    width: 50%;
}

.ul-experience li::before {
    content: "\2022";
    color: #FDCA37; 
    font-weight: bold;
    font-size: 30px; 
    position: absolute;
    display: inline-block; 
    width: 1em; 
    left: -18px;
    top: -14px;
}
/* .ul-experience li::after{
    content: " ";
    border: 1px solid #FDCA37;
    position: absolute;
    display: inline-block;
    height: 80%;
    top: 20px;
    left: -12px;    
} */

.ul-experience li:last-child::after{
    content: none;    
}

.title-container {
    margin-top: 30px;
}
.study-content,
.kemampuan-content {
    padding-left: 22px;
    margin-top: 20px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial;
    font-weight: bold;
    padding-right: 20px;
}

.ul-study {
    padding-left: 0 !important;
    list-style: none;
    font-weight: normal;
}

.ul-study li {
    margin-bottom: 10px;
}

.fakultas-container {
    display: flex;    
    flex-direction: row;
    justify-content: space-between;
    color: #5c5858;
    font-size: 13px;
    margin-top: 5px;
    padding-right: 20px;
}

.tahun-lulus {
    text-align: right;
}

.kemampuan-content {
    margin-top: 40px;
}
.progress-content {
    margin-bottom: 10px;
}

.w3-blue, .w3-hover-blue:hover {
    background-color: #FDCA37 !important;
    height: 10px;
}