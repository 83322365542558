.btn-edit {
    padding: 0 5px !important;
    font-size: 11px !important;
}

.btn-edit .fa {
    display: unset;
}

.sidebar .nav-dropdown-items .nav-item{
    margin-left: 15px;
}

.alert-absolute{
    position: fixed;
    right: 10px;
    bottom: 50%;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #fee2e1; */
    background-color: rgba(254, 226, 225, 0.75);
    border-color: #fdd6d6;
    border-radius: 0.25rem;
    width: 300px;
}

.table-kontak-outlet tbody tr.outlet-active td {
    background-color: #4578de;
    color : #fff;    
}

.table-kontak-outlet tbody tr td {
    cursor: pointer
}

.alert-absolute:hover{
    background-color: rgb(254, 226, 225);
}

.alert-absolute .alert-text {
    flex: 4;
    color: #813838;        
    padding: 0.75rem 1.25rem;
    /* max-width: 3000px; */
}

.alert-absolute .close-button {
    flex : 1;    
    padding: 0 10px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.box-billing-detail .row.form-group {
    margin-bottom: 1px !important;
}

.box-bayar .row.form-group {
    margin-bottom: 5px !important;
}

.box-bayar .row.form-group.refund-box{
    margin-bottom: 20px !important;
}

.box-bayar .row.form-group.box-lebih{
    margin-bottom: 20px !important;
    padding-bottom: 10px !important;
}

.box-lebih .form-control::placeholder {
    color : #bfc6ce
}

.box-bayar .row.form-group.refund-box label {
    color : #9b0101;
    font-weight: bold;
    padding-bottom: 18px;
}

.box-billing-detail .table {
    margin-top: 10px;
}

.no-bottom .form-group {
    margin-bottom: 0 !important;
}

.box-change-room .form-check-input {
    margin-left: 0 !important;
}

.table-center thead tr th {
    text-align: center;
    vertical-align: middle;
}

.table-small thead tr th,
.table-small tbody tr td{
    font-size: 12px;
}

.table-extra-small thead tr th,
.table-extra-small tbody tr td{
    font-size: 10px;    
}

.table-sm-padding tbody tr td {
    padding: 5px !important;
}

.table-sm-padding thead tr th {
    padding: 8px !important;
}

.table-header-withbackground thead tr th {
    background-color: #133279 !important;
    color: #FFFFFF;
}

.table tbody tr.tr-bold td {
    font-weight: bold;
}

.table tbody tr.tr-account-child td.td-account-child {
    padding-left: 15px !important;
}



/* .table tbody tr.tr-account-child td.td-account-child::before {
    content: "- ";
} */

/* modal */
/* .custom-map-modal {
    .modal-dialog {
     width: 100%;
     height: 100%;
     margin: 0;
     padding: 0;
   }
   .modal-content {
     height: auto;
     min-height: 100%;
     border-radius: 0;
  }
} */

.transaksi-kas-title {    
    margin : 0 auto;    
    font-size: 18px;
    font-weight: bold;
}

.transaksi-kas-desc {
    margin-bottom: 10px;
    font-size: 16px;
}

.company-name {
    font-size : 18px;
    font-weight: bold;
}

.company-address, 
.company-city, 
.company-phone{
    font-size: 12px;
} 

.margin-top-10 {
    margin-top : 30px; 
}

.margin-bottom-10 {
    margin-bottom : 10px; 
}

.margin-bottom-20 {
    margin-bottom : 20px; 
}

.margin-bottom-30 {
    margin-bottom : 30px; 
}

.margin-bottom-5 {
    margin-bottom : 5px; 
}

.custom-container {
    position: relative;    
    background-color: white;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    height: 85vh;
    padding: 20px 30px;    
}

.custom-container .form-group {
    margin-bottom: 1px;
}

.custom-container .form-group select,
.custom-container .form-group label {
    font-size: 12px;
}

.custom-container .box-bayar .form-group {
    margin-bottom: 5px !important;
}

.custom-container .btn-box {
    position: absolute;
    bottom : 0;
    left : 0; 
    margin-right: 0x;   
}

.custom-container .btn-box .col-lg-4 {
    padding-right: 0;
}

.custom-container .btn-box .btn {
    height: 50px;
    border-radius: 0 !important;
    padding: 0 !important;
}

.custom-container .btn-box .btn-save {
    width: 50%;
}


.sidebar {
    background-color: #3c4b64 !important;
}


.sidebar .nav-link.active {
    background-color:     rgba(255, 255, 255, 0.05) !important;
}


.sidebar .nav-dropdown-items .nav-item {
    margin-left: 0 !important;
}

.sidebar .nav-dropdown-items .nav-item a.nav-link {
    padding-left: 30px !important;
}

.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
    padding-left: 0 !important;
}

.card-header {
    background-color: #fff !important;
    border-bottom: 1px solid #c8ced3;
}

.card-produk-detail .row.form-group {
    margin-bottom : 2px !important;
}

.jumbotron {
    padding: 1.5rem 2rem !important;
}

.produk-foto-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
}


.produk-foto-container .foto-value {
    position: relative;
    margin-bottom: 20px;
    margin-right: 10px;
}
.produk-foto-container .foto-value img {
    max-height: 200px;
    /* max-width: 200px; */
    width: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
}

.produk-foto-container .foto-value .btn-delete {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 1;
    width: 30px;
    height: 30px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 0.5px solid #cecece;
}

.produk-foto-container .foto-value .foto-setting {
    padding: 8px 10px;
    font-size: 12px;
    border: 1px solid #cecece;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.produk-foto-container .foto-value .foto-setting .form-check-input{
    position: relative;
    margin-top: 0 !important;
    margin-left: 0 !important;
}

.form-group-retur {
    margin-bottom:  1px !important;
}

.form-check-input-retur {
    margin-left: 0 !important;
}

.input-diskon {
    border: none !important;
    border-radius: 0 !important;
    padding : 0 !important;
    border-bottom: 1px solid #c4c7ca !important;
}

.input-diskon-order {
    border: none !important;
    border-radius: 0 !important;
    padding : 0 !important;
    border-bottom: 1px solid #c4c7ca !important;
}

.table-order tfoot tr td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.form-group-pos {
    margin-bottom: 1px !important;
}

.konsumen-card,
.konsumen-card .form-control {
    font-size: 12px !important;
}

.konsumen-card .input-group-append .btn {
    padding: 0 .50rem !important;
}

.konsumen-card {
    margin-bottom: .5rem !important;
}

.open-sales-list {
    font-size: 13px;
}

.main .hide {
    display: none !important;
}

.form-group-filter-date .react-datepicker-popper {
    position: absolute; 
    will-change: transform; 
    top: 0px; 
    left: -100px !important; 
    transform: translate3d(15px, -256px, 0px);
}

.without-padding-bottom .form-group {
    margin-bottom: 0 !important;
}
.without-padding-bottom .form-group label{
    margin-bottom: 0 !important;
}

.without-padding-5 .form-group,
.without-padding-5 .form-group label {
    margin-bottom: 2px !important;
}

.table.text-middle tbody tr td {
    vertical-align: middle;
}

.react-datepicker-popper[data-placement^="bottom"] {
    z-index: 99;
}

.container-bayar {
    background-color: #d7d9df82;
    padding: 20px;
    border-radius: 10px;
}

/* .app-header {
    background-color: #F7941D !important;
} */

.billprint-container {
    background-color: white;
    width: 21cm;
    min-height: 29cm; 
    padding: .5cm;
}

.billprint-container.landscape {
    background-color: white;
    width: 29cm;
    height: auto; 
    padding: .5cm;
    min-height: 21cm; 
}

.billprint-container .company-name {
    font-size : 18px;
    font-weight: bold;
}

.billprint-container .company-address, 
.billprint-container .company-city, 
.billprint-container .company-phone{
    font-size: 16px;
} 

.billprint-container .title {
    margin-top: 14px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color : #384392
}

.billprint-container .desc-title {
    text-align: center;
    font-size: 14px;
}

.billprint-container .form-group {
    margin-bottom: 0 !important;
}






 

.form-group-pembelian-cetak label{
    margin-bottom: 0 !important;
}

.table-deposite-struk tbody tr td {
    padding: 5px 5px 5px 5px;
}

.table-permissions tbody tr.permission-active td {
    background-color: #4578de;
    color : #fff
}

.react-datepicker-popper[data-placement^="top"] {    
    z-index: 999;
}

.nav-link {
    font-size: 12px !important;
}

.welcome-dashboard{
    background-color: #689be3;
    padding: 1rem;
    margin-bottom: 20px;
    border-radius: 5px;
}

.welcome-dashboard h1 {
    font-weight: bold;
    color: #ffffff;
}

/* =========================== dasboard  ======================== */
.kategori-dasboard-container {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

.kategori-dasboard-container .kategori-title {
    font-size: 28px;
    font-weight: bold;
}

/* .sidebar .nav-link {    
    padding: 0.5rem 1rem !important;
} */
/* textarea.form-control:valid{
    border-color: #e4e7ea !important;    
} */

/* textarea:invalid,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.was-validated .form-check-input:invalid ~ .form-check-label, 
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before{
    border-color: yellow !important;
    background-color: blue;
    outline: none;
}

.invalid-tooltip, .invalid-feedback,
.was-validated .form-control:invalid, .form-control.is-invalid {    
    background-color: blue;
    border-radius: 0.25rem;
    outline: none;
  } */


/* ======================== KALENDER ============================ */
.hari_libur {
    color: #d10000;
}
.hari_sabtu {
    color: inherit !important;
}



.table-absen thead tr {
    background-color: #fff;
    /* border-left: 2px solid #cecece !important; */
}

.week_end {
    background-color: #f8d7da !important;
    color : #721c24;
}

.btn-group-pegawai div {
    margin-bottom: 5px;
}


.foto-container {
    margin-top: 30px;     
}

.foto-container img {
    border-radius: 8px;
    border: 1px solid #cecece;
    max-height: 120px;
    width: auto;    
    justify-content: center;    
}



table tbody tr td.absen-alpha {
    background-color: rgb(248, 215, 218);
    color : rgb(114, 28, 36);
}

table tbody tr td.absen-sakit {
    background-color: #d1ecf1;
    color : #0c5460;
}

table tbody tr td.absen-izin {
    background-color: #cce5ff;
    color : #004085;
}

table tbody tr td.absen-cuti {
    background-color: #fff3cd;
    color :#856404;
}

.table-absen tbody tr td {
    vertical-align: middle;
}